export function GameTimer() {
  const timerEl = document.querySelector('#gameHUD .timer__txt');

  const start = async (timeInSeconds) => {
    const result = await startTimer(timeInSeconds, timerEl);
    return result;
  };

  const runDemo = async (timeInSeconds) => {
    const result = await startDemo(timeInSeconds, timerEl);
    return result;
  };

  const killTimer = () => {
    clearInterval(window.matchGameInterval);
  };

  return { start, runDemo, killTimer };
}

function startTimer(duration, display) {
  return new Promise((resolve) => {
    let timer = duration;
    let minutes;
    let seconds;
    let currentStep = 2; // 2 because demo is step 1

    window.matchGameInterval = setInterval(function () {
      // console.log(window.gameState.getState());
      let challengeComplete = window.gameState.getState().currentChallengeComplete;

      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      display.textContent = minutes + ':' + seconds;

      if (challengeComplete) {
        currentStep++;

        const nextGameStepTrigger = new CustomEvent('gotoNextGameStep', {
          detail: {
            nextStep: currentStep,
            gameState: window.gameState.getState(),
            overallState: window.gameState.getOverallState(),
          },
        });

        window.gameState.clearState();

        if (currentStep === 10) {
          clearInterval(window.matchGameInterval);
          window.matchGameInterval = null;
        }

        window.dispatchEvent(nextGameStepTrigger);
      }

      if (--timer < 0) {
        clearInterval(window.matchGameInterval);
        display.textContent = '0:00';
        resolve('Timer Elapsed');
      }
    }, 1000);
  });
}

function startDemo(duration, display) {
  return new Promise((resolve) => {
    let timer = duration;

    const interval = setInterval(function () {
      if (--timer < 0) {
        clearInterval(interval);

        const nextGameStepTrigger = new CustomEvent('gotoNextGameStep', {
          detail: {
            nextStep: 2,
            gameState: window.gameState.getState(),
            overallState: window.gameState.getOverallState(),
          },
        });

        window.gameState.clearState();
        window.dispatchEvent(nextGameStepTrigger);
        resolve('Demo Finished');
      }
      // let challengeComplete = window.gameState.getState().currentChallengeComplete;

      // minutes = parseInt(timer / 60, 10);
      // seconds = parseInt(timer % 60, 10);

      // minutes = minutes < 10 ? minutes : minutes;
      // seconds = seconds < 10 ? '0' + seconds : seconds;

      // display.textContent = minutes + ':' + seconds;

      // if (challengeComplete) {
      //   currentStep++;

      // }

      // if (--timer < 0) {
      //   clearInterval(interval);
      //   display.textContent = '0:00';
      //   resolve('Demo Finished');
      // }
    }, 1000);
  });
}
