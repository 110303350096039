export function GameData() {
  let currentColumnData = [];
  let currentData = [];

  const getColumnData = (gameStep) => {
    let data = {
      name: 'columns',
      columnHeader: '',
      columnLead: '',
      columnRows: [
        { type: 'Get', match: 1 },
        { type: 'Earn', match: 2 },
        { type: 'Enhance', match: 3 },
        { type: 'Experience', match: 4 },
      ],
    };

    switch (gameStep) {
      case 1:
        data.columnHeader = '20 Nights';
        data.columnLead = 'or 35,000 Base Points';
        data.columnRows = [
          { type: 'Get', match: false },
          { type: 'Earn', match: 2 },
          { type: 'Enhance', match: 3 },
          { type: 'Experience', match: 4 },
        ];
        break;

      case 2:
        data.columnHeader = '30 Nights';
        data.columnLead = 'or 50,000 Base Points';
        break;

      case 3:
        data.columnHeader = '40 Nights';
        data.columnLead = 'or 65,000 Base Points';
        break;

      case 4:
        data.columnHeader = '50 Nights';
        data.columnLead = 'or 80,000 Base Points';
        data.columnRows = [
          { type: 'Get', match: false },
          { type: 'Earn', match: 2 },
          { type: 'Enhance', match: 3 },
          { type: 'Experience', match: 4 },
        ];
        break;

      case 5:
        data.columnHeader = '60 Nights';
        data.columnLead = 'or 100,000 Base Points';
        data.columnRows = [
          { type: 'Get', match: '*' },
          { type: 'Get', match: '*' },
          { type: 'Get', match: '*' },
          { type: 'Get', match: '*' },
        ];
        break;

      case 6:
        data.columnHeader = '<span class="headline-tight">70, 80, 90<br> Nights</span>';
        data.columnLead = '';
        break;

      case 7:
        data.columnHeader = '<span class="headline-tight">100<br> Nights</span>';
        data.columnLead = '';
        break;

      case 8:
        data.columnHeader = '<span class="headline-tight">110, 120, 130,<br> 140 Nights</span>';
        data.columnLead = '';
        break;

      case 9:
        data.columnHeader = '<span class="headline-tight">150<br> Nights</span>';
        data.columnLead = '';
        break;

      default:
        break;
    }

    currentColumnData = data;
    return data;
  };

  const getCardsData = (gameStep) => {
    const data = {
      name: 'answers',
      answers: [],
    };

    switch (gameStep) {
      case 1:
        data.answers = [
          {
            type: 'answer',
            img: 'images/perks/card-2k-next-stay.png',
            match: 2,
            autoPopulate: true,
          },
          {
            type: 'answer',
            img: 'images/perks/card-2-club-access-awards.png',
            match: 3,
            autoPopulate: true,
          },
          {
            type: 'answer',
            img: 'images/perks/card-25-find-credit.png',
            match: 4,
            autoPopulate: true,
          },
          {
            type: 'answer',
            img: 'images/perks/card-2-guest-of-honor.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-1-category-1-7.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-10000-bonus-points.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-1-suite-upgrade.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-5000-bonus-points.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-h-my-hyatt-concierge.png',
            match: false,
          },
        ];
        break;

      case 2:
        data.answers = [
          {
            type: 'answer',
            img: 'images/perks/card-1-category-1-4.png',
            match: 1,
            autoPopulate: true,
          },
          {
            type: 'answer',
            img: 'images/perks/card-2k-next-stay.png',
            match: 2,
            autoPopulate: true,
          },
          {
            type: 'answer',
            img: 'images/perks/card-2-club-access-awards.png',
            match: 3,
            autoPopulate: true,
          },
          {
            type: 'answer',
            img: 'images/perks/card-25-find-credit.png',
            match: 4,
          },
          {
            type: 'answer',
            img: 'images/perks/card-1-guest-of-honor.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-10000-bonus-points.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-1-suite-upgrade.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-300-find-credit.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-1-category-1-7.png',
            match: false,
          },
        ];
        break;

      case 3:
        data.answers = [
          {
            type: 'answer',
            img: 'images/perks/card-1-guest-of-honor.png',
            match: 1,
          },
          {
            type: 'answer',
            img: 'images/perks/card-5000-bonus-points.png',
            match: 2,
            autoPopulate: true,
          },
          {
            type: 'answer',
            img: 'images/perks/card-1-suite-upgrade.png',
            match: 3,
          },
          {
            type: 'answer',
            img: 'images/perks/card-150-find-credit.png',
            match: 4,
            autoPopulate: true,
          },
          {
            type: 'answer',
            img: 'images/perks/card-2k-next-stay.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-1-category-1-7.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-2-club-access-awards.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-25-find-credit.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-10000-bonus-points.png',
            match: false,
          },
        ];
        break;

      case 4:
        data.answers = [
          {
            type: 'answer',
            img: 'images/perks/card-5000-bonus-points.png',
            match: 2,
          },
          {
            type: 'answer',
            img: 'images/perks/card-2-suite-upgrade.png',
            match: 3,
            autoPopulate: true,
          },
          {
            type: 'answer',
            img: 'images/perks/card-150-find-credit.png',
            match: 4,
          },
          {
            type: 'answer',
            img: 'images/perks/card-1-suite-upgrade.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-2k-next-stay.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-2-club-access-awards.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-25-find-credit.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-10000-bonus-points.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-1-category-1-7.png',
            match: false,
          },
        ];
        break;

      case 5:
        data.answers = [
          {
            type: 'answer',
            img: 'images/perks/card-2-guest-of-honor.png',
            match: '*',
          },
          {
            type: 'answer',
            img: 'images/perks/card-1-category-1-7.png',
            match: '*',
            autoPopulate: true,
          },
          {
            type: 'answer',
            img: 'images/perks/card-2-suite-upgrade.png',
            match: '*',
          },
          {
            type: 'answer',
            img: 'images/perks/card-h-my-hyatt-concierge.png',
            match: '*',
            autoPopulate: true,
          },
          {
            type: 'answer',
            img: 'images/perks/card-300-find-credit.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-1-category-1-4.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-1-guest-of-honor.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-10000-bonus-points.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-1-suite-upgrade.png',
            match: false,
          },
        ];
        break;

      case 6:
        data.answers = [
          {
            type: 'answer',
            img: 'images/perks/card-1-guest-of-honor.png',
            match: 1,
          },
          {
            type: 'answer',
            img: 'images/perks/card-10000-bonus-points.png',
            match: 2,
            autoPopulate: true,
          },
          {
            type: 'answer',
            img: 'images/perks/card-1-suite-upgrade.png',
            match: 3,
            autoPopulate: true,
          },
          {
            type: 'answer',
            img: 'images/perks/card-300-find-credit.png',
            match: 4,
          },
          {
            type: 'answer',
            img: 'images/perks/card-25-find-credit.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-2k-next-stay.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-1-category-1-7.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-2-club-access-awards.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-2-suite-upgrade.png',
            match: false,
          },
        ];
        break;

      case 7:
        data.answers = [
          {
            type: 'answer',
            img: 'images/perks/card-1-category-1-7.png',
            match: 1,
          },
          {
            type: 'answer',
            img: 'images/perks/card-10000-bonus-points.png',
            match: 2,
            autoPopulate: true,
          },
          {
            type: 'answer',
            img: 'images/perks/card-1-suite-upgrade.png',
            match: 3,
          },
          {
            type: 'answer',
            img: 'images/perks/card-m-miraval-extra-night.png',
            match: 4,
          },
          {
            type: 'answer',
            img: 'images/perks/card-h-my-hyatt-concierge.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-2-club-access-awards.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-25-find-credit.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-2k-next-stay.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-2-suite-upgrade.png',
            match: false,
          },
        ];
        break;

      case 8:
        data.answers = [
          {
            type: 'answer',
            img: 'images/perks/card-1-guest-of-honor.png',
            match: 1,
          },
          {
            type: 'answer',
            img: 'images/perks/card-10000-bonus-points.png',
            match: 2,
          },
          {
            type: 'answer',
            img: 'images/perks/card-1-suite-upgrade.png',
            match: 3,
            autoPopulate: true,
          },
          {
            type: 'answer',
            img: 'images/perks/card-m-miraval-extra-night.png',
            match: 4,
          },
          {
            type: 'answer',
            img: 'images/perks/card-300-find-credit.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-1-category-1-4.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-5000-bonus-points.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-2k-next-stay.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-2-guest-of-honor.png',
            match: false,
          },
        ];
        break;

      case 9:
        data.answers = [
          {
            type: 'answer',
            img: 'images/perks/card-1-ultimate-free-night.png',
            match: 1,
          },
          {
            type: 'answer',
            img: 'images/perks/card-10000-bonus-points.png',
            match: 2,
          },
          {
            type: 'answer',
            img: 'images/perks/card-1-suite-upgrade.png',
            match: 3,
          },
          {
            type: 'answer',
            img: 'images/perks/card-m-miraval-extra-night.png',
            match: 4,
          },
          {
            type: 'answer',
            img: 'images/perks/card-25-find-credit.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-1-guest-of-honor.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-1-category-1-7.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-300-find-credit.png',
            match: false,
          },
          {
            type: 'answer',
            img: 'images/perks/card-2k-next-stay.png',
            match: false,
          },
        ];
        break;

      default:
        break;
    }

    // shuffle the data
    for (let i = data.answers.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      let temp = data.answers[i];
      data.answers[i] = data.answers[j];
      data.answers[j] = temp;
    }

    currentData = data;
    return data;
  };

  const getCurrentData = () => {
    return currentData;
  };

  const getCurrentColumnData = () => {
    return currentColumnData;
  };

  return { getColumnData, getCardsData, getCurrentData, getCurrentColumnData };
}
