import { gsap } from 'gsap';
import { Flip } from 'gsap/Flip';
import { Cards } from './Cards';
import { GameText } from './GameText';

export function GameContainer() {
  let gameContainer;

  const create = () => {
    gameContainer = new Container();
  };

  const advanceColumn = (data, firstRound) => {
    gameContainer.advanceColumn(data, firstRound);
  };

  const advanceCards = (data) => {
    gameContainer.advanceCards(data);
  };

  const autoPopulateHints = (delay) => {
    gameContainer.autoPopulateHints(delay);
  };

  const showDropdown = (text) => {
    gameContainer.showDropdown(text);
  };

  const tallyMatches = () => {
    gameContainer.tallyMatches();
  };

  const createTextPop = (text) => {
    gameContainer._createTextPop(text);
  };

  return {
    create,
    advanceColumn,
    advanceCards,
    tallyMatches,
    autoPopulateHints,
    showDropdown,
    createTextPop,
  };
}

class Container {
  constructor() {
    gsap.registerPlugin(Flip);

    this.cards = new Cards();
    this.gameText = new GameText();
    this.outerContainer = document.querySelector('section.game-container');
    this.columnContainer = document.getElementById('answersColumn');
    this.currentColumnCards = [];
    this.cardsContainer = document.getElementById('answersPool');
    this.currentCards = [];
    this.wildCardCount = 0;
  }

  async advanceColumn(data, firstRound = false) {
    if (!firstRound) {
      this.gameText.updateText({
        header: window.gameData.getCurrentColumnData().columnHeader,
        lead: window.gameData.getCurrentColumnData().columnLead,
      });
      await this.cards.hide('columns');
    }

    this.cards.clearColumns();
    this._createCards(data, this.columnContainer);
    setTimeout(() => {
      this.cards.show('columns');
    }, 250);
  }

  autoPopulateHints(delay) {

    this.outerContainer.style.pointerEvents = 'none';

    setTimeout(() => {
      const allCards = document.querySelectorAll('#answersPool .card');
      const drags = document.querySelectorAll('#answersPool .card[data-auto-pop="true"]');
      const num = drags.length;

      if (num == 0) {
        this.outerContainer.style.pointerEvents = 'all';
      }

      drags.forEach((drag, i) => {
        const dataMatch = drag.getAttribute('data-match');
        let destDrop;
        if (dataMatch === '*') {
          // handle all GET column
          destDrop = document.querySelectorAll('#answersColumn .card')[i];
        } else {
          destDrop = document.querySelector('#answersColumn .card[data-match="' + dataMatch + '"]');
        }

        const dragInner = drag.querySelector('.answer-card-inner');
        const state = Flip.getState(dragInner);
        const destState = Flip.getState(destDrop);

        drag.setAttribute('disabled', true);
        dragInner.classList.add('auto-dropped');
        destDrop.setAttribute('data-match-success', true);

        const droppableIndex = window.currentDroppables.indexOf(destDrop);
        window.currentDroppables.splice(droppableIndex, 1);

        Flip.fit(state, destState, {
          duration: 1,
          ease: 'power3.inOut',
          absolute: true,
          delay: i,
          onStart: () => {
            allCards.forEach((el) => {
              el.style.zIndex = 0;
            });
            drag.style.zIndex = 1;
          },
          onComplete: () => {
            window.gameState.updateMatches(true);
            destDrop.appendChild(dragInner);

            if (i === num - 1) {
              this.outerContainer.style.pointerEvents = 'all';
            }
          },
        });
      });
    }, delay);
  }

  showDropdown(text) {
    const hudDropdown = document.querySelector('.hud-dropdown');
    hudDropdown.innerHTML = text;
    hudDropdown.classList.add('show');

    setTimeout(() => {
      hudDropdown.classList.remove('show');

      setTimeout(() => {
        hudDropdown.innerHTML = 'Get Ready...';
        hudDropdown.classList.add('show');

        setTimeout(() => {
          hudDropdown.classList.remove('show');
        }, 3000);
      }, 1500);
    }, 5000);
  }

  tallyMatches() {
    let successCount = 0;
    const matchResults = document.querySelectorAll('#answersColumn [data-match-success]');
    const dropContainer = document.querySelector('.col--drop');
    const poolContainer = document.querySelector('.col--pool');
    const hudDropdown = document.querySelector('.hud-dropdown');

    // swap z-index so score pops show above cards in the pool
    poolContainer.style.zIndex = 0;
    hudDropdown.style.zIndex = 1;
    dropContainer.style.zIndex = 2;

    matchResults.forEach((el, i) => {
      el.classList.add('pulse');
      if (el.getAttribute('data-match-success') === 'true') {
        successCount++;
        setTimeout(() => {
          el.classList.add('pulse-success');
          this._createScorePop(el, 'success');
          window.gameScore.addPoints(10);
        }, (i / 5) * 1000);
      } else {
        setTimeout(() => {
          el.classList.add('pulse-fail');
          this._createScorePop(el, 'fail');
          // this._showCorrectAnswer(el);
          window.gameScore.removePoints(5);
        }, (i / 5) * 1000);

        // wait for score pops to show correct answers
        setTimeout(() => {
          this._showCorrectAnswer(el);
        }, (i / 5) * 1000 + 1500); // NOTE:
      }
    });

    // put z-index back to normal
    setTimeout(() => {
      dropContainer.style.zIndex = 'unset';
      poolContainer.style.zIndex = 'unset';
      hudDropdown.style.zIndex = 2;
    }, 1500);
  }

  async advanceCards(data, firstRound = false) {
    if (!firstRound) {
      await this.cards.hide('answers');
    }

    this._createCards(data, this.cardsContainer);
    setTimeout(() => {
      this.cards.show('answers');
    }, 250);
  }

  _createCards = (data, container) => {
    // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~');
    // console.log(data, container);
    // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~');
    if (data.name == 'answers') {
      this.currentCards = [];
      data.answers.forEach((answer) => {
        const card = this.cards.create('answer', answer, container);
        this.currentCards.push(card);
      });
    } else if (data.name == 'columns') {
      data.columnRows.forEach((row) => {
        const card = this.cards.create('column', row, container);
        this.currentColumnCards.push(card);
      });
    }
  };

  _createTextPop = (text) => {
    const container = document.getElementById('hyattGame4');
    const txtPop = document.createElement('div');

    txtPop.classList.add('text-pop');
    txtPop.textContent = text;
    container.appendChild(txtPop);

    gsap.set(txtPop, { opacity: 0, scale: 0 });
    gsap.to(txtPop, { duration: 0.25, opacity: 1, scale: 1, ease: 'power3.in' });
    gsap.to(txtPop, {
      duration: 1.5,
      delay: 0.25,
      ease: 'power3.out',
      scale: 15,
      opacity: 0,
      onComplete: () => {
        txtPop.remove();
      },
    });
  };

  _createScorePop = (parent, type) => {
    const className = type == 'success' ? 'score-pop--success' : 'score-pop--fail';
    const scoreTxt = type == 'success' ? '+10' : '-5';
    const scorePop = document.createElement('div');

    scorePop.classList.add('score-pop');
    scorePop.classList.add(className);
    scorePop.textContent = scoreTxt;
    parent.appendChild(scorePop);

    Flip.fit(scorePop, document.querySelector('#gameHUD .score__txt'), {
      duration: 1.5,
      ease: 'power3.out',
      scale: true,
      opacity: 1,
      onComplete: () => {
        scorePop.remove();
      },
    });
  };

  _showCorrectAnswer = (el) => {
    const allCards = document.querySelectorAll('.card');
    const columnCards = document.querySelectorAll('#answersColumn .card');
    const correctMatch = el.getAttribute('data-match');
    let isInPool = true;
    // const incorrectMatch = el.querySelector();
    let correctMatchEls;
    let dragInner;

    const dropContainer = document.querySelector('.col--drop');
    const poolContainer = document.querySelector('.col--pool');
    poolContainer.style.zIndex = 2;
    dropContainer.style.zIndex = 0;

    if (correctMatch === '*') {
      // grab the wildcard matches that are not auto populated
      correctMatchEls = this.cardsContainer.querySelectorAll(
        '.card[data-match="*"][data-auto-pop="false"]'
      );
      dragInner = correctMatchEls[this.wildCardCount].querySelector('.answer-card-inner');
      isInPool = true;
      this.wildCardCount++;
    } else {
      dragInner = document.querySelector(
        '.answer-card-inner[data-drop-match="' + correctMatch + '"]'
      );
      if (
        this.cardsContainer.querySelector(
          '.answer-card-inner[data-drop-match="' + correctMatch + '"]'
        )
      ) {
        isInPool = true;
      } else {
        isInPool = false;
      }
    }

    const destination = el.querySelector('.card-inner');
    // console.log('inner : ', dragInner);
    // console.log('dest : ', destination);

    const state = Flip.getState(dragInner);
    const destState = Flip.getState(destination);

    // const droppableIndex = window.currentDroppables.indexOf(el);
    // window.currentDroppables.splice(droppableIndex, 1);
    columnCards.forEach((card) => {
      gsap.set(card, { zIndex: 0 });
    });
    gsap.set(el, { zIndex: 1 });
    const currentInnerEl = el.querySelector('.answer-card-inner:not([data-drop-match])');
    if (currentInnerEl) {
      // currentInnerEl.style.zIndex = 0;
      gsap.to(currentInnerEl, { duration: 1, ease: 'power3.out', opacity: 0 });
    }

    // if in the drop column, append now
    if(!isInPool) {
      destination.appendChild(dragInner);
    }

    Flip.fit(state, destState, {
      duration: 1,
      ease: 'power3.inOut',
      absolute: true,
      delay: 0,
      onStart: () => {
        allCards.forEach((element) => {
          element.style.zIndex = 0;
        });
        el.style.zIndex = 1;
      },
      onComplete: () => {
        poolContainer.style.zIndex = 'unset';
        dropContainer.style.zIndex = 'unset';
      },
    });
  };
}
