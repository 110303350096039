import { gsap } from 'gsap';
import { Flip } from 'gsap/Flip';
import { Draggable } from 'gsap/Draggable';

export function Cards() {
  let currentCards = [];
  let currentColumnCards = [];
  let cardId = 0;
  let cardCounter = 0;
  let posY = 0;
  let nextSolution = 0;
  window.currentDroppables = [];
  window.matchCounter = 0;

  const create = (cardType, data, container) => {
    // console.log('type: ' + cardType);
    // console.log('data: ', data.match);
    if (cardType == 'answer') {
      const card = new Card('answer', data, container, cardId);
      currentCards.push(card);
      cardId++;
    } else if (cardType == 'column') {
      const card = new Card('column', data, container, cardId);
      currentColumnCards.push(card);
      cardId++;
    }
  };

  const show = (cardType) => {
    // const hitBlock = document.querySelector('.game-container > .hit-blocker');
    // hitBlock.style.display = 'none';
    if (cardType == 'columns') {
      currentColumnCards.forEach((card) => {
        card.show();
      });
    } else if (cardType == 'answers') {
      currentCards.forEach((card) => {
        card.show();
      });
    }
  };

  const hide = async (cardType) => {
    if (cardType == 'columns') {
      return await Promise.all(
        currentColumnCards.map(async (card) => {
          // console.log(card);
          const p = await card.hide();
          p.remove(); // remove from DOM
        }),
      );
    } else if (cardType === 'answers') {
      return await Promise.all(
        currentCards.map(async (card) => {
          // console.log(card);
          const p = await card.hide();
          p.remove(); // remove from DOM
        }),
      );
    }
  };

  // const kill = () => {
  //   currentCards.forEach((card) => {
  //     card.kill();
  //   });
  // };

  const clearColumns = () => {
    currentColumnCards = [];
    window.currentDroppables = [];
  };

  const clearAnswers = () => {
    currentCards = [];
  };

  class Card {
    constructor(cardType, data, container, id) {
      gsap.registerPlugin(Flip, Draggable);
      // console.log('------------------------------------------------');
      // console.log(cardType, data.match, container, id);
      // console.log('------------------------------------------------');

      this.cardType = cardType;
      this.animation = false;

      if (cardType == 'column') {
        this.markup = `<div id="card-${id}" class="card" data-match="${data.match}">
          <div class="card-inner">
            <div class="card-body">
              <p class="mb-0">${data.type}</p>
            </div>
          </div>
        </div>`;
      } else if (cardType == 'answer') {
        const autoPop = data.autoPopulate ? true : false;
        const dataDropMatch = data.match ? 'data-drop-match="' + data.match + '"' : '';
        this.markup = `<div id="answer-card-${id}" class="card" data-match="${data.match}" data-auto-pop="${autoPop}" style="top: ${this.posY}px; left: ${this.posX}px;">
          <div class="answer-card-inner" ${dataDropMatch}>
            <div class="answer-card-img" style="background-image: url(${data.img})">
            </div>
          </div>
        </div>`;
      }

      container.innerHTML += this.markup;

      setTimeout(() => {
        if (cardType == 'answer') {
          this.domRef = document.getElementById('answer-card-' + id);
          this.parent = document.querySelector('#answersColumn [data-match="' + data.match + '"]');
          this.draggable = Draggable.create(this.domRef.querySelector('.answer-card-inner'), {
            bounds: document.getElementById('hyattGame4'),
            inertia: true,
            onDragStart: this.onDragStart,
            onDrag: this.onDrag,
            onDragEnd: this.onDragEnd,
          });

          this.draggable = this.draggable[0];
        } else if (cardType == 'column') {
          this.domRef = document.getElementById('card-' + id);
          if(this.domRef.getAttribute('data-match') != 'false') {
            window.currentDroppables.push(this.domRef);
          }
        }
      }, 0);

      this.onDragStart = (e) => {
        const allCards = document.querySelectorAll('#answersPool .card');

        allCards.forEach((el) => {
          el.style.zIndex = 0;
        });

        this.domRef.style.zIndex = 1;
      };

      this.onDrag = (e) => {
        let i = window.currentDroppables.length;

        while (--i > -1) {
          if (this.draggable.hitTest(window.currentDroppables[i], '50%')) {
            window.currentDroppables[i].classList.add('highlight');
          } else {
            window.currentDroppables[i].classList.remove('highlight');
          }
        }
      };

      this.onDragEnd = (e) => {
        window.currentDroppables.forEach((el) => {
          el.classList.remove('highlight');
        });

        let i = window.currentDroppables.length;

        while (--i > -1) {
          if (this.draggable.hitTest(window.currentDroppables[i], '50%')) {
            const dragEl = this.domRef.querySelector('.answer-card-inner');
            const target = window.currentDroppables[i];
            const dest = target.querySelector('.card-inner');

            let isMatch =
              target.getAttribute('data-match') == this.domRef.getAttribute('data-match');

            target.setAttribute('data-match-success', isMatch);

            const state = Flip.getState(dragEl);
            const destState = Flip.getState(dest);
            dest.appendChild(dragEl);

            window.currentDroppables.splice(i, 1);
            this.draggable.disable();

            Flip.fit(state, destState, {
              duration: 0.25,
              ease: 'power3.out',
              absolute: true,
              onComplete: () => {},
            });

            window.gameState.updateMatches(isMatch);
          } else {
            const dragEl = this.domRef.querySelector('.answer-card-inner');
            let x = 0;
            let y = 0;

            gsap.to(dragEl, {
              duration: 0.35,
              ease: 'power3.out',
              x: x,
              y: y,
            });
          }
        }
      };
    }

    show() {
      if (this.cardType == 'column') {
        gsap.set(this.domRef, {
          // scale: 0.75,
          x: '-=300',
          // rotationY: 45,
          opacity: 0,
        });

        this.animation = gsap.to(this.domRef, {
          duration: 0.5,
          // scale: 1,
          x: 0,
          // rotationY: 0,
          opacity: this.domRef.getAttribute('data-match') == 'false' ? 0.25 : 1,
          ease: 'power3.inOut',
        });
      } else if (this.cardType == 'answer') {
        gsap.set(this.domRef, {
          // scale: 5,
          opacity: 0,
        });

        gsap.to(this.domRef.querySelector('.answer-card-inner'), 0.5, {
          rotation: 'random(-15, 15)',
          delay: 'random(0, 0.35)',
          ease: 'power3.inOut',
        });

        this.animation = gsap.to(this.domRef, 0.5, {
          // scale: 1,
          opacity: 1,
          delay: 'random(0, 0.35)',
          ease: 'power3.inOut',
        });
      }
    }

    hide() {
      return new Promise((resolve) => {
        if (this.cardType == 'column') {
          this.animation = gsap.to(this.domRef, {
            duration: 0.5,
            // scale: 0.75,
            x: '-=300',
            // rotationY: -45,
            opacity: 0,
            // delay: 'random(0, 0.25)',
            // delay: 0.75,
            ease: 'power3.inOut',
            onComplete: () => {
              resolve(this.domRef);
            },
          });
        } else if (this.cardType == 'answer') {
          this.animation = gsap.to(this.domRef, {
            duration: 0.75,
            // scale: 0.5,
            opacity: 0,
            rotation: 'random(-25, 25)',
            // delay: 'random(0, 0.35)',
            ease: 'power3.inOut',
            onComplete: () => {
              resolve(this.domRef);
            },
          });
        }
      });
    }

    // kill() {
    //   this.animation.kill();
    // }
  }

  return {
    create,
    show,
    hide,
    clearAnswers,
    clearColumns,
  };
}
