export function GameState() {
  let state;

  const create = () => {
    state = new State();
  };

  const updateMatches = (isMatch) => {
    state.updateMatches(isMatch);
  };

  const getState = () => {
    return state.state;
  };

  const setState = (data) => {
    state.setState(data);
  };

  const clearState = () => {
    state.clearState();
  };

  const getOverallState = () => {
    return state.overallState;
  };

  return { create, updateMatches, getState, setState, clearState, getOverallState };
}

class State {
  constructor() {
    this.state = {
      matchesRequired: 4,
      successfulMatches: 0,
      failedMatches: 0,
      totalMatches: 0,
      currentChallengeComplete: false,
      currentChallengeSuccess: false,
    };

    this.overallState = {
      successfulRounds: 0,
      failedRounds: 0,
    };
  }

  updateMatches(isMatch) {
    this.state.totalMatches++;
    // console.log('matches req: ', this.state.matchesRequired);
    // console.log('total matches: ', this.state.totalMatches);

    if (isMatch) {
      this.state.successfulMatches++;
    } else {
      this.state.failedMatches++;
    }

    if (this.state.totalMatches === this.state.matchesRequired) {
      // const hitBlock = document.querySelector('.game-container > .hit-blocker');
      // hitBlock.style.display = 'block';

      this.state.currentChallengeComplete = true;

      if(this.state.successfulMatches === this.state.matchesRequired) {
        // win
        this.state.currentChallengeSuccess = true;
        this.overallState.successfulRounds++;
      } else {
        this.state.currentChallengeSuccess = false;
        this.overallState.failedRounds++;
      }

    }
  }

  setState(data) {
    let currentMatchCount = 0;

    data.forEach((item) => {
      if (item.match != false) {
        currentMatchCount++;
      }
    });

    this.state = {
      matchesRequired: currentMatchCount,
      successfulMatches: 0,
      failedMatches: 0,
      totalMatches: 0,
      currentChallengeComplete: false,
      currentChallengeSuccess: false,
    };
  }

  clearState() {
    this.state = {
      matchesRequired: 0,
      successfulMatches: 0,
      failedMatches: 0,
      totalMatches: 0,
      currentChallengeComplete: false,
      currentChallengeSuccess: false,
    };
  }
}
