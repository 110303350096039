import { gsap } from 'gsap';

export function GameText() {
  const updateText = (textObj) => {
    const el = document.querySelector('.column-headline .inner');
    const headline = document.querySelector('.column-headline .headline');
    const lead = document.querySelector('.column-headline .lead');

    gsap.to(el, {
      duration: 0.65,
      // scale: 0.6,
      x: '-=300',
      // rotationY: -45,
      opacity: 0,
      ease: 'power3.inOut',
      onComplete: () => {
        headline.innerHTML = textObj.header;
        lead.innerHTML = textObj.lead;

        gsap.set(el, {
          // scale: 0.6,
          // x: '+=800px',
          // rotationY: 45,
          opacity: 0,
        });

        gsap.to(el, {
          duration: 0.65,
          scale: 1,
          x: 0,
          // rotationY: 0,
          opacity: 1,
          ease: 'power3.inOut',
        });
      },
    });
  };

  return { updateText };
}
