import { gsap } from 'gsap';
import { GameData } from './components/GameData';
import { GameTimer } from './components/GameTimer';
import { GameScore } from './components/GameScore';
import { GameState } from './components/GameState';
import { GameContainer } from './components/GameContainer';

let appContainer;
let gameTimer;
let gameData;
let gameScore;
let gameState;
let gameContainer;
let gameCover;
let gameCoverContent;
let answersColumn;
let answersPool;
let instructionsTxt;
let gameComplete = false;

const _handleResize = () => {
  let windowScale = window.innerWidth / 2048;
  appContainer.style.transform = 'scale(' + windowScale + ')';
};

const initMilestoneMatchApp = (teamName) => {
  appContainer = document.getElementById('hyattGame4');

  window.addEventListener('resize', _handleResize);
  _handleResize();

  // Create the game state instance
  gameState = window.gameState = new GameState();
  gameState.create();

  // Create the game container instance
  gameContainer = window.gameContainer = new GameContainer();
  gameContainer.create();

  // Create the game score instance
  gameScore = window.gameScore = new GameScore();
  gameScore.create(0);

  // Create the game timer instance
  gameTimer = new GameTimer();

  // Create the game data instance
  gameData = window.gameData = new GameData();

  answersColumn = document.getElementById('answersColumn');
  answersPool = document.getElementById('answersPool');

  let userDetails = (window.userDetails = {
    nickname: '',
    email: '',
    location: '',
    score: 0,
  });

  const form = document.querySelector('#section-login form');
  const inputs = document.querySelectorAll('.user-input');
  form.addEventListener('submit', handleSubmit);

  function handleSubmit(e) {
    e.preventDefault();
    inputs.forEach((input) => {
      userDetails[input.id] = input.value;
    });
    // console.log(userDetails);
    runGame();
  }

  gameCover = document.getElementById('gameCover');
  gameCoverContent = gameCover.querySelector('.content');

  const _hideCover = () => {
    gsap.to(gameCover, {
      duration: 0.5,
      opacity: 0,
      ease: 'power3.inOut',
    });
  };
  _hideCover();
};

const runGame = async () => {
  // Reset Button
  const resetButton = document.getElementById('gameResetBtn');
  resetButton.addEventListener('click', (e) => {
    e.preventDefault();
    location.reload();
    return false;
  });

  // set delay for overlay screen before game
  const instructionsDelay = 6;

  // fade overlay in and out
  gsap.set(gameCoverContent, { opacity: 1 });
  gsap.to(gameCover, {
    duration: 0.5,
    opacity: 1,
    ease: 'power3.inOut',
    onComplete: () => {
      resetButton.classList.remove('d-none');

      gsap.to(gameCover, {
        delay: instructionsDelay,
        duration: 0.5,
        opacity: 0,
        ease: 'power3.inOut',
      });
    },
  });

  // hide the 'login' screen and show the game
  document.getElementById('section-login').style.display = 'none';
  const gameContainerEl = document.querySelector('.game-container');
  gameContainerEl.style.display = 'flex';
  gameContainerEl.style.opacity = 0;

  gsap.to(gameContainerEl, {
    duration: 0.5,
    opacity: 1,
    ease: 'power3.inOut',
  });

  // add listener for next stage
  window.addEventListener('gotoNextGameStep', _handleNextGameStep);

  /*
   * Kickoff Round 1 (Demo round), start the overall timer and handle when the timer expires
   */
  const columnData = gameData.getColumnData(1);
  const cardsData = gameData.getCardsData(1);
  gameState.setState(columnData.columnRows);
  await _sleep(instructionsDelay * 1000 + 500);
  gameContainer.advanceColumn(columnData, true);
  gameContainer.advanceCards(cardsData, true);
  // start demo
  gameContainer.showDropdown('Demo');
  gameContainer.autoPopulateHints(2000);
  await _sleep(5000); // wait for auto-populate
  gameContainer.tallyMatches(); // tally matches
  await gameTimer.runDemo(4); // wait for demo to finish
  gameContainer.createTextPop('GO!');
  // end demo, start game
  await gameTimer.start(120);

  if (!gameComplete) {
    // game not completed before timer elapsed, kill game, proceed to last screen
    _handleNextGameStep({
      detail: {
        gameState: window.gameState.getState(),
        overallState: window.gameState.getOverallState(),
        nextStep: 10,
        timerElapsed: true,
      },
    });
  }
};

const _handleNextGameStep = async (e) => {
  // console.log('next step: ', e.detail.nextStep);
  let newColumnData;
  let newCardsData;

  if (e.detail.nextStep !== 2) {
    let delayCount = document.querySelectorAll('#answersColumn [data-match-success="false"]').length;
    gameContainer.tallyMatches();
    await _sleep((delayCount * 1000) + 1000); // NOTE: wait for score pop animations and correct answers
  }

  if (e.detail.nextStep === 5) {
    // Step 6 has all 'GET' items, hide the 'CHOOSE' background
    document.querySelector('#answersColumn .choose-bg').style.opacity = 0;
  }

  if (e.detail.nextStep === 6) {
    // Show the 'CHOOSE' background again
    document.querySelector('#answersColumn .choose-bg').style.opacity = 1;
  }

  if (e.detail.nextStep === 10) {
    gameComplete = true;

    let endMsg = 'Nice work! You finished before the timer elapsed.';

    if (e.detail.timerElapsed === true) {
      endMsg = 'Nice try! The timer elapsed before you were able to finish.';
    } else {
      clearInterval(window.matchGameInterval);
      window.matchGameInterval = null;
    }

    const coverContent = `<div class="w-100 d-flex flex-column align-items-center" style="max-width: 1000px;">
          <p class="text-white fs-5 mb-4">${endMsg}</p>
          <h1 class="text-white bg-line w-100">Your Score</h1>
          <h1 class="text-white" style="font-size: 125px;">${gameScore.getScore()}</h1>
        </div>`;
    gameCoverContent.innerHTML = coverContent;

    // post user details & score
    window.userDetails.score = window.gameScore.getScore();
    // console.log('posting... ', window.userDetails);
    (async () => {
      const rawResponse = await fetch('https://glasswingeffect.com/api/game-4-scores', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(window.userDetails),
      });
      const content = await rawResponse.json();

      // console.log(content);
    })();

    // show game end overlay
    gsap.set(gameCoverContent, { opacity: 1 });
    gsap.to(gameCover, {
      duration: 0.5,
      opacity: 1,
      ease: 'power3.inOut',
      delay: 1,
      onComplete: () => {
        setTimeout(() => {
          document.getElementById('gameResetBtn').click();
        }, 10000);
      },
    });
  }

  if (e.detail.nextStep !== 2) {
    await _sleep(1000);
  }

  newColumnData = gameData.getColumnData(e.detail.nextStep);
  newCardsData = gameData.getCardsData(e.detail.nextStep);
  gameState.setState(newColumnData.columnRows);
  gameContainer.advanceColumn(newColumnData);
  gameContainer.advanceCards(newCardsData);
  gameContainer.autoPopulateHints(1800);
  await _sleep(250);
};

const _sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

// DOM loaded Init
document.addEventListener('DOMContentLoaded', function () {
  let teamName = '';

  if (window.self !== window.top) {
    // we're iframed
    window.addEventListener('message', (evt) => {
      if (!evt?.data?.type) return;

      if (evt.data.type === 'start-game') {
        if (evt.data.station) {
          teamName = 'team-' + evt.data.station;
        } else {
          teamName = 'team-a';
        }
        initMilestoneMatchApp(teamName);
      }
    });

    window.parent.postMessage({ type: 'game-ready' }, '*');
  } else {
    // we're local
    setTimeout(() => {
      teamName = 'team-a';
      initMilestoneMatchApp(teamName);
    }, 1000);
  }
});
