import { gsap } from 'gsap';

export function GameScore() {
  let score;

  const create = (startPoints) => {
    score = new Score(startPoints);
  };

  const addPoints = (points) => {
    score.addPoints(points);
  };

  const removePoints = (points) => {
    score.removePoints(points);
  };

  const getScore = () => {
    return score.totalScore;
  };

  const postScore = () => {
    // console.log('Posting to parent... ', score.totalScore);
    window.parent.postMessage({ type: 'game-score', score: score.totalScore }, '*');
  };

  return { create, addPoints, removePoints, getScore, postScore };
}

class Score {
  constructor(startPoints) {
    this.totalScore = startPoints;
    this.scoreTxt = document.querySelector('#gameHUD .score__txt');
    this.scoreTxt.textContent = this.totalScore;
  }

  addPoints(points) {
    this.totalScore += points;

    gsap.to(this.scoreTxt, {
      duration: 0.25,
      innerText: this.totalScore,
      snap: {
        innerText: 1,
      },
    });

    gsap.to(this.scoreTxt, {
      duration: 0.85,
      color: '#BDCB77',
      ease: 'power3.out',
      onComplete: () => {
        gsap.to(this.scoreTxt, {
          duration: 0.85,
          color: '#000000',
          ease: 'power3.in',
        });
      },
    });
  }

  removePoints(points) {
    this.totalScore -= points;
    if (this.totalScore <= 0) {
      this.totalScore = 0;
    }

    gsap.to(this.scoreTxt, {
      duration: 0.25,
      innerText: this.totalScore,
      snap: {
        innerText: 1,
      },
    });

    gsap.to(this.scoreTxt, {
      duration: 0.85,
      color: '#C22628',
      ease: 'power3.out',
      onComplete: () => {
        gsap.to(this.scoreTxt, {
          duration: 0.85,
          color: '#000000',
          ease: 'power3.in',
        });
      },
    });
  }
}
